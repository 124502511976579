<template>
  <b-card
    no-body
    class="small-card small-card-hover p-0"
    :class="fromBackoffice ? 'pointer' : ''"
    @click="fromBackoffice ? openModal(item) : ''"
  >
    <!-- Iconos presencial en linia -->
    <div
      v-if="
        ['Event', 'Project', 'Course'].includes(space.mainType) &&
        space.modality === 'hybrid'
      "
      class="d-flex zindex-4 position-absolute mt-50 ml-75"
    >
      <span
        v-if="!item.modality || item.modality === 1"
        v-b-tooltip.top
        :title="$t('events.event-details.online')"
      >
        <b-iconstack font-scale="1.6">
          <b-icon stacked icon="circle-fill" variant="success" />
          <b-icon stacked icon="tv-fill" scale="0.58" variant="white" />
        </b-iconstack>
      </span>
      <span
        v-if="!item.modality || item.modality === 2"
        v-b-tooltip.top
        class="ml-25"
        :title="$t('events.event-details.on-site')"
      >
        <b-iconstack font-scale="1.6">
          <b-icon title="on-site" stacked icon="circle-fill" variant="info" />
          <b-icon stacked icon="geo-alt-fill" scale="0.58" variant="white" />
        </b-iconstack>
      </span>
    </div>
    <!-- Targeta -->
    <b-card-body
      class="flex-column align-items-center pointer p-2 justify-content-center"
    >
      <!-- Element Name -->
      <div
        v-if="
          (fromBackoffice && item.discounts && item.discounts.length > 0) ||
          discount
        "
        class="flag small-flag d-flex flex-column align-items-center"
      >
        <p
          v-if="fromBackoffice && item.discounts && item.discounts.length > 0"
          class="m-0 text-white font-small-1"
        >
          Has discounts
        </p>
        <p v-if="discount" class="m-0 text-white font-small-2">
          <span v-if="discount.discount > 0">{{ discount.discount }}</span>
          <span v-else>0</span>
          <span v-if="discount.isPercentage">%</span
          ><span v-else>{{ item.currencySymbol }}</span>
          OFF
        </p>
      </div>
      <h4 class="mb-0 mt-2 text-center title" style="min-height: 45px">
        {{ translate(item.name) }}
      </h4>

      <div
        class="text-primary d-flex align-items-center justify-content-center price"
      >
        <text-resizable
          :text-style="'text-primary text-center mb-0 h5'"
          :text="total(item)"
          :default-font-size="2"
          :default-text-length="7"
          :font-size-to-rest="0.2"
          :exponential-font-size-rest="0.03"
        />
        <small v-if="item.interval === 'month'" class="font-small-1 ml-25">
          /
          <span v-if="item.intervalCount && item.intervalCount > 1"
            >{{ item.intervalCount }}
            {{ $t("onboarding.subscriptions.months") }}</span
          ><span v-else>{{ $t("onboarding.subscriptions.month") }}</span>
        </small>
        <small v-if="item.interval === 'year'" class="font-small-1 ml-25">
          / {{ $t("onboarding.subscriptions.year") }}
        </small>
      </div>
      <div
        v-if="!fromBackoffice && discount"
        class="d-flex align-items-center min-height-1 mt-n50 font-small-2 justify-content-center"
      >
        <p class="text-muted mb-0">
          PVR:
          <span style="text-decoration: line-through"
            >{{ item.total }} {{ item.currencySymbol }}</span
          >
        </p>
      </div>
      <div v-else class="min-height-1" />
      <div
        v-if="Object.keys(item.description).length > 0"
        class="min-height-5 mb-1"
      >
        <p class="text-muted font-small-1 mb-0 mt-0">
          {{ $t("onboarding.subscriptions.includes") }}:
        </p>
        <div
          v-for="(benefit, index) in translate(item.description).split('\n')"
          :key="index"
          class="d-flex"
        >
          <b-icon-check2
            v-if="benefit !== ''"
            variant="primary"
            font-scale="0.8"
            class="mr-50 check mt-25"
          />
          <div v-if="benefit !== ''" class="text-secondary font-small-2 m-0">
            {{ benefit }}
          </div>
        </div>
      </div>
      <div v-else class="min-height-5 mb-1">
        <p class="mb-0" />
      </div>
      <div
        v-if="fromBackoffice"
        class="w-100 mt-50 d-flex justify-content-between"
      >
        <b-button
          class="mx-3 px-0 mt-0 w-100 font-small-1"
          size="sm"
          variant="primary"
        >
          {{ $t("onboarding.subscriptions.subscribe") }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import TextResizable from "@/backoffice/components/TextResizable.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { totalCard } from "@/store/collective/plans-helpers";

export default {
  name: "PlansSmallCard",
  components: {
    TextResizable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
    discount: {
      type: Object,
      default: null,
    },
  },
  computed: {
    fromBackoffice() {
      return this.$route.path.includes("backoffice");
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    total(item) {
      return totalCard(item, this.discount);
    },
    openModal(item) {
      this.$emit("openModal", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.price {
  width: 100%;
  height: 43.95px;
}
.small-card {
  min-height: 19.4rem;
  height: auto !important;
}
.check {
  min-width: 12px;
}
</style>
