<template>
  <section>
    <b-row v-if="actualPage === 'plans'">
      <!-- ** ORDER ** -->
      <b-col
        v-for="item in plans"
        :key="item.key"
        class="flex-column d-flex align-items-center"
      >
        <plans-small-card
          :item="item"
          :space="space"
          :discount="discounts[item.key]"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { discounts } from "@/store/collective/plans-helpers";
import PlansSmallCard from "@/@core/components/plans/PlansSmallCard.vue";

export default {
  name: "ItemsPlans",
  components: {
    PlansSmallCard,
  },
  props: {
    modelType: {
      Type: String,
      required: true,
    },
    type: {
      Type: String,
      required: true,
    },
    itemKey: {
      Type: String,
      required: true,
    },
    space: {
      Type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPlansModalVisible: false,
      subscription: {},
      selectedLanguage: "",
      selectedPlan: {},
      page: "plans",
    };
  },
  computed: {
    plans() {
      if (this.$store.getters.subscriptionPlans) {
        return this.$store.getters.subscriptionPlans.unpaginated;
      }
      return [];
    },
    discounts() {
      return discounts(this.plans);
    },
    actualPage() {
      if (this.$route.query.page) {
        this.page = this.$route.query.page;
      }
      return this.page;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
    this.selectedLanguage = this.currentLocale;
    await this.fetchData();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch("getItemsPayments", {
        itemType: "subscriptionPlans",
        page: 1,
        perPage: 16,
        requestConfig: {
          orderBy: -1,
          count: 16,
          slug: this.space.slug,
        },
      });
      this.isLoading = false;
    },
    firstToUpperCase(item) {
      return item[0].toUpperCase() + item.substring(1);
    },
  },
};
</script>
