<template>
  <div class="mt-1">
    <!-- Solo con invitación -->
    <div v-if="!item.endDate && !item.startDate && item.mainType==='Event'">
      <b-button disabled class="w-100" variant="outline-primary">
        {{ $t(`${itemType}.details.coming-soon-message`) }}
      </b-button>
    </div>
    <!-- Evento exterior -->
    <b-link
      v-else-if="item.registrationURL"
      :href="item.registrationURL"
      class="h-100"
      target="_blank"
    >
      <b-button
        v-if="!isOver(item.endDate)"
        class="w-100"
        variant="primary"
      >
        {{ $t(`${itemType}.details.event-link`) }}
      </b-button>
      <b-button v-else class="w-100" variant="primary">
        {{ $t(`${itemType}.details.event-finished-external`) }}
      </b-button>
    </b-link>
    <!-- Comunidad -->
    <enter-button-new
      v-else-if="itemCommunity"
      :community="itemCommunity"
      class="w-100"
      :item-type="itemType"
    />
  </div>
</template>

<script>
import EnterButtonNew from '@/@core/components/EnterButtonNew.vue';
// import moment from 'moment';

export default {
  name: 'CommunitiesOthersButton',
  components: {
    EnterButtonNew,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    itemCommunity: {
      type: Object,
      required: true,
      default: () => {},
    },
    itemType: {
      type: String,
      required: true,
      default: ''
    },
  },

  watch: {
    itemCommunity(value) {
      return value;
    },
    item(value) {
      return value;
    },
  },
  methods: {
    isOver(endDate, startDate) {
      if(!endDate && !startDate){
        return null;
      }
      const isEndDate = this.$moment(endDate) || this.$moment(startDate).endOf('day');
      return this.$moment(Date.now()).isAfter(isEndDate);
    },
  },

};
</script>

<style>

</style>
