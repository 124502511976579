<template>
  <base-grid-container
    v-slot="{ item }"
    :items="items"
    :loading="isLoading"
    :loading-next="isLoadingNextPage"
    :placeholder="computedPlaceholder"
    :placeholder-message="$t('available.message', { itemName: itemName })"
    :has-more="hasMore"
    :error="errorMessage"
    @load-next="handleLoadNextPage"
  >
    <slot :item="item" />
  </base-grid-container>
</template>

<script>
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import RoomsPlaceholder from '@/assets/images/placeholders/light/rooms.svg';
import BaseGridContainer from './base/BaseGridContainer.vue';

export default {
  components: { BaseGridContainer },
  props: {
    id: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    filter: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: [Boolean, String],
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      errorMessage: null,
    };
  },
  computed: {
    items() {

      const items = this.$store.getters[this.id].unpaginated || [];

      if (this.filter) return items.filter(this.filter);
      return items;
    },
    // TODO add sections empty img, dont exist
    computedPlaceholder() {
      if (this.placeholder) return this.placeholder;

      if (this.id === 'events') return EventsPlaceholder;

      if (this.id === 'rooms') return RoomsPlaceholder;

      return EventsPlaceholder;
    },
    hasMore() {
      // TODO Implement when scrolling is implemented
      return false;
    },
  },
  created() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('getItemsNode', {
          itemType: this.id,
          page: 1,
          perPage: 50,
          requestConfig:{
            orderByDate: -1
          }
        });
        this.errorMessage = null;
        this.$emit('items-loaded', response);
      } catch (error) {
        this.errorMessage = this.$t('error-message.load-failed');
      }
      this.isLoading = false;
    },
    handleLoadNextPage() {
      // TODO
    },
  },
};
</script>
