<template>
  <base-horizontal-container
    v-if="itemsData"
    :loading="isLoading"
    :loading-next="isLoadingNextPage"
    :items="items"
    :total="showTotal && itemsData ? itemsData.meta.total : null"
    :placeholder="placeholder"
    :placeholder-message="placeholderMessage || $t('available.message', { itemName: itemName })"
    :scroll-container-class="scrollContainerClass"
    :scrollable="scrollable"
    @load-next="handleLoadOfNewItems"
  >
    <template #default="slotData">
      <slot v-bind="slotData" />
    </template>
    <template #footer="slotData">
      <slot name="footer" v-bind="slotData" />
    </template>
  </base-horizontal-container>
</template>

<script>
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import RoomsPlaceholder from '@/assets/images/placeholders/light/rooms.svg';
import PhotoPlaceholder from '@/assets/images/placeholders/light/photo.svg';
import BaseHorizontalContainer from './base/BaseHorizontalContainer.vue';

export default {
  name: 'HorizontalContainer',
  components: { BaseHorizontalContainer },
  props: {
    id: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    placeholderMessage: {
      type: String,
      default: '',
    },
    filter: {
      type: Function,
      default: null,
    },
    requestConfig: {
      type: Object,
      default: () => {},
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    scrollContainerClass: {
      type: String,
      default: '',
    },
    /**
     * Used to force the component to show the scroll arrows
     */
    scrollable: Boolean,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters[`${this.id}`];
    },
    items() {
      const paginatedItems = Object
        .values(this.itemsData.paginated)
        .reduce((array, items) => [...array, ...items], []);

      if (this.filter) {
        return paginatedItems.filter(this.filter);
      }

      return paginatedItems;
    },
    placeholder() {
      if (this.id === 'communityMembers') return MembersPlaceholder;

      if (this.id === 'events') return EventsPlaceholder;

      if (this.id === 'rooms') return RoomsPlaceholder;

      if (this.id === 'photos') return PhotoPlaceholder;

      return PhotoPlaceholder;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    async fetchData() {
      const response = await this.$store.dispatch('getItemsNode', {
        itemType: this.id,
        page: this.lastLoadedPage,
        perPage: 16,
        requestConfig: this.requestConfig,
      });
      this.$emit('items-loaded', response);
      this.$emit('items-change', this.items);
      this.isLoading = false;
    },
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;

        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
  },
};
</script>
