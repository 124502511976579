<template>
  <base-vertical-container
    :items="searchValue ? results : items"
    :total="total"
    :placeholder="placeholder"
    :placeholder-message="$t('available.message', { itemName: itemName })"
    :per-page="perPage"
    :loading="isLoading"
    :loading-next-page="isLoading"
    @load-next="handleLoadNextPage"
  >
    <template #top>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
    </template>

    <template #item="{ item }">
      <slot :item="item" />
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </base-vertical-container>
</template>

<script>
import FaqsPlaceholder from "@/assets/images/placeholders/light/faqs.svg";
import PostsPlaceholder from "@/assets/images/placeholders/light/posts.svg";
import PresentationsPlaceholder from "@/assets/images/placeholders/light/presentations.svg";

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core';
import BaseVerticalContainer from "./base/BaseVerticalContainer.vue";

export default {
  name: "VerticalContainer",

  setup() {
    const target = ref(null);
    const targetIsVisible = useElementVisibility(target);

    return {
      target,
      targetIsVisible,
    };
  },

  components: { BaseVerticalContainer },

  props: {
    id: {
      type: String,
      default: "",
    },
    itemName: {
      type: String,
      default: "",
    },
    requestConfig: {
      type: Object,
      default: () => {},
    },
    perPage: {
      type: Number,
      default: 16,
    },
    totalModifier: {
      type: Boolean,
    },
    noLoading: {
      type: Boolean,
    },
    searchValue: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      total: null,
      // isLoading: this.noLoading ? false : true,
      isLoading: false,
      // isLoadingNextPage: false,
      storedItems: [],
      // itemsData: [],
      currentPage: 0,
      results: [],
    };
  },

  computed: {
    placeholder() {
      if (this.id === "faqs") {
        return FaqsPlaceholder;
      }
      if (this.id === "posts") {
        return PostsPlaceholder;
      }
      if (this.id === "presentations") {
        return PresentationsPlaceholder;
      }
      return "";
    },
    items() {
      // this.itemsData = this.$store.getters[this.id].unpaginated;
      return this.$store.getters[this.id].unpaginated.length
        ? this.$store.getters[this.id].unpaginated
        : [];
    },
  },
  watch: {
    targetIsVisible(newValue) {
      // console.log("++++++++++++ --> WATCH!!! VerticalContainer.vue (targetIsVisible): ", this.id, newValue)
      if (newValue && !this.items.length) {
        // console.log("2 --> WATCH!!! VerticalContainer.vue (targetIsVisible): ", this.id, newValue)
        this.handleLoadNextPage();
      }
    },

    searchValue() {
      this.searchItem();
    },
    totalModifier() {
      this.modifyTotal();
    },
  },

  methods: {
    modifyTotal() {
      this.total -= 1;
    },
    async handleLoadNextPage() {
      // console.log('VerticalContainer.vue handleLoadNextPage (id, currentPage): ', this.id, this.currentPage);
      this.currentPage += 1;
      await this.loadPage({ page: this.currentPage, perPage: this.perPage });
    },
    async loadPage({ page, perPage }) {
      // console.log('VerticalContainer.vue loadPage(id, { page, perPage }): ', this.id, page, perPage);
      this.isLoading = true;
      const response = await this.$store.dispatch("getItems", {
        itemType: this.id,
        page,
        perPage,
        requestConfig: this.requestConfig,
      });
      this.total = response?.meta?.total;
      this.currentPage = response?.meta?.current_page;
      this.storedItems = response?.data;
      this.isLoading = false;
      return response?.data;
    },

    searchItem() {
      const searchTerm = this.searchValue.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.storedItems.filter((item) => Object.values(item.question)[0].toLowerCase()
        .includes(searchTerm));
    },
  },
};
</script>
