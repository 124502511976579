import GridContainer from '@core/components/containers/GridContainer.vue';
import HorizontalContainer from '@core/components/containers/HorizontalContainer.vue';
import VerticalContainer from '@core/components/containers/VerticalContainer.vue';
import ListContainer from '@core/constants/ListContainer';

// ContainerMixin.js
export default {
  props: {
    container: {
      type: String,
      default: ListContainer.Horizontal,
      validator(container) {
        return Object.values(ListContainer).includes(container);
      },
    },
  },
  computed: {
    containerComponent() {
      switch (this.container) {
        case ListContainer.Horizontal:
          return HorizontalContainer;
        case ListContainer.Vertical:
          return VerticalContainer;
        case ListContainer.Grid:
          return GridContainer;
        default:
          throw new Error('Invalid container');
      }
    },
  },
};
