<template>
  <b-row class="m-0">
    <b-col
      v-for="(item, index) of Object.values(buttons)"
      :key="index"
      :cols="
        [1, 3, 8].includes(community.status) ||
        Object.values(buttons).length === 1
          ? '12'
          : '6'
      "
      class="px-25 mt-25"
      :class="item.classes"
    >
      <b-link :href="item.url || '#'" :target="item.target ? '_blank' : ''">
        <b-button
          :variant="item.variant ? item.variant : 'primary'"
          :disabled="item.variant === 'outline-primary' ? true : false"
          class="w-100 h-100"
          @click="item.action"
        >
          <feather-icon v-if="item.icon" :icon="item.icon" class="mr-25" />
          {{ item.text }}
        </b-button>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  checkCommunityDatesNew,
  checkOnboardingWithoutMemberParams,
  checkCommunityStartAndEnd,
  checkCommunityRegisterStartAndEnd,
  checkOccupation,
} from "@/store/collective/store-helpers";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import Service from "@/config/service-identifiers";
import moment from "moment";

export default {
  name: "EnterButtonNew",
  mixins: [ToastNotificationsMixin],
  props: {
    community: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttons: {}, // El botón puede tener: { text: String, url?: String, action?: Function, classes?: String}
      isHybrid: false,
      isLoading: false,
      userJoined: false,
    };
  },
  computed: {
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    hasUnsubscribeButton() {
      return !["organizations"].includes(this.itemType);
    },
  },
  created() {
    this.checkAction();
  },
  methods: {
    checkAction() {
      const outline = "outline-primary";
      const link = "link";
      const insideSpaceURL = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        this.community.subdomain || "app"
      ).replace("{slug}", this.community.slug);

      const welcomeURL = `${insideSpaceURL}/welcome`;
      // revisamos si es hibrido o no.
      if (this.community.modality === "hybrid") {
        // Espacio hibrido. Habilitamos el doble botón
        this.isHybrid = true;
      }
      
      //console.log('community:', this.community);
      const communityMemberStatus = this.community.status;
      console.log('community member status:', communityMemberStatus);

      // Revisamos el status del usuario en la comunidad.
      if (communityMemberStatus === 3) {
        // Si el status es 3 revisaremos las fechas de la comunidad
        const canAccessByDate = checkCommunityDatesNew(this.community);
        console.log('Can access by dates?', canAccessByDate);
        if (!canAccessByDate) {
          // Botón de no se puede entrar al espacio con un return final
          if (
            this.community.closingDate &&
            this.isOver(this.community.closingDate)
          ) {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-finished`),
              variant: outline,
            };
          } else {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-join-closed`),
              variant: outline,
            };
          }
        } else if (this.community.joinURL) {
          // Puede acceder. Pero el link es exterior
          if (this.isOver(this.community.endDate)) {
            this.buttons.access = {
              text: this.$t(
                `${this.itemType}.details.event-finished-external`
              ),
              url: this.community.joinURL,
              target: true,
            };
          } else {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-link`),
              url: this.community.joinURL,
              target: true,
            };
          }
        } else {
          // Puede acceder. Le mandamos al espacio
          this.buttons.access = {
            text: this.$t("welcome.enter"),
            url: insideSpaceURL,
          };
        }
        if (this.hasUnsubscribeButton && !this.isOver(this.community.closingDate)) {
          // Si el status es 3 o 8 ya eres miembro de la comunidad y por lo tanto puedes desincribir-te
          if (this.community.memberModality === 1) {
            // Espacio onsite. Ahora generamos el botón del onsite para desapuntarte
            if (this.isHybrid) {
              this.buttons.unsubscribeOnSite = {
                text: this.$t(
                  `enter-button.${this.itemType}.unsubscribe-on-site`
                ),
                variant: link,
                action: this.unsubscribe,
              };
            } else {
              this.buttons.unsubscribeOnSite = {
                text: this.$t(`enter-button.${this.itemType}.unsubscribe`),
                variant: link,
                action: this.unsubscribe,
              };
            }
          }
          if (this.community.memberModality === 0) {
            // Espacio online. Ahora generamos el botón del online para desapuntarte
            if (this.isHybrid) {
              this.buttons.unsubscribeOnline = {
                text: this.$t(
                  `enter-button.${this.itemType}.unsubscribe-online`
                ),
                variant: link,
                action: this.unsubscribe,
              };
            } else {
              this.buttons.unsubscribeOnline = {
                text: this.$t(`enter-button.${this.itemType}.unsubscribe`),
                variant: link,
                action: this.unsubscribe,
              };
            }
          }
        }
      } else if (communityMemberStatus === 7) {
        this.buttons.accept = {
          text: this.$t("welcome.accept"),
          action: () => this.join(),
          icon: "CheckIcon",
          variant: "success",
        };
        this.buttons.decline = {
          text: this.$t("welcome.reject"),
          action: () => this.declineInvitation(),
          icon: "XIcon",
          variant: "danger",
        };
      } else if (communityMemberStatus === 1) {
        this.buttons.access = {
          text: this.$t("welcome.request"),
          url: welcomeURL,
          variant: outline,
        };
      } else if (communityMemberStatus === 8) {
        // Si el status es 8 revisamos el onboarding
        const typeOfOnboarding = checkOnboardingWithoutMemberParams(
          this.community
        );
        console.log('typeOfOnboarding:', typeOfOnboarding);
        if (typeOfOnboarding === "form") {
          // Redirect al form con return
          this.buttons.access = {
            text: this.$t("welcome.onboarding-form"),
            action: () => this.redirectToOnboardingForm()
              // this.$router.replace({
              //   name: "onboarding-form",
              //   params: { communityId: this.community.slug },
              // }),
          };
        } else if (typeOfOnboarding === "payments") {
          //console.log("hauria danar aqui");
          // Redirect a payments con return
          this.buttons.access = {
            text: this.$t("welcome.onboarding-form"),
            action: () =>
              this.$router.replace({
                name: "onboarding-plans",
                params: { communityId: this.community.slug },
              }),
          };
        } else {
          // JoinAfterPay
          this.buttons.access = {
            text: this.$t("welcome.enter"),
            url: insideSpaceURL,
          };
        }
      } else {
        // Si el status no estaba dentro de esos consideramos que no es miembro 
        // del espacio ni ha iniciado un proceso de registro
        console.log('No eres miembro de este espacio ni has iniciado un proceso de registro.');

        // Revisamos las horas de la comunidad (registrationStartDate, registrationEndDate)
        if (!checkCommunityRegisterStartAndEnd(this.community)) {
          console.log('El registro no está disponible, fuera de fechas');
          // Botón de el espacio aun no está abierto para registro con un return
          if (this.isOver(this.community.registrationEndDate)) {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-register-finish`),
              variant: outline,
            };
          } else {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-register-closed`),
              variant: outline,
            };
          }
          return;
        }

        // Revisamos las horas de la comunidad (startDate, endDate)
        if (!checkCommunityStartAndEnd(this.community)) {
          console.log('Espacio cerrado, fuera de fechas');
          // Botón de el espacio no ha empezado con un return
          if (
            this.community.closingDate &&
            this.isOver(this.community.closingDate)
          ) {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-finished`),
              variant: outline,
            };
          } else {
            this.buttons.access = {
              text: this.$t(`${this.itemType}.details.event-join-closed`),
              variant: outline,
            };
          }
        }

        // Miramos la accesibilidad si es cerrada solo con invitación
        if (this.community.accessibility >= 2) {
          console.log('Espacio cerrado, sólo invitación');
          // Botón de solo invitación con un return
          if (
            this.itemType !== "organizations"
          ) {
            this.buttons.access = {
              text: this.$t("welcome.invitation-only"),
              variant: outline,
            };
          }
          return;
        }

        // Por ultimo reviso la accesibilidad final para asignar una acción al botón
        /*if (this.community.accessibility === 0) {
          console.log('Espacio abierto sin solicitud');
          if(!this.buttons?.length || this.buttons?.length <= 0){
            if (this.community.modality === "online" || this.isHybrid) {
              // Espacio online o hibrido. Ahora generamos el botón del online
              this.buttons.Online = {
                  text: this.$t(`enter-button.${this.itemType}.subscribe-online`),
                  modality: "online",
                };
            }
            if (this.community.modality === "on-site" || this.isHybrid) {
              // Espacio on-site o hibrido. Ahora generamos el botón del onsite
              this.buttons.Onsite = {
                  text: this.$t(`enter-button.${this.itemType}.subscribe-on-site`),
                  modality: "on-site",
                };
            }
          }
          // Le añadimos al botón la acción join
          for (const row of Object.keys(this.buttons)) {
            if (this.buttons[row].action !== "closed") {
              this.buttons[row].action = () =>
                this.join(this.buttons[row].modality);
            }
          }
        }*/

        if (this.community.accessibility <= 1) {
          console.log('Espacio abierto, te puedes registrar');
          if(!this.buttons?.length || this.buttons?.length <= 0){
            if(this.community.modality || this.isHybrid){
              if (this.community.modality === "online" || this.isHybrid) {
                // Espacio online o hibrido. Ahora generamos el botón del online
                this.buttons.Online = {
                  text: this.$t(`enter-button.${this.itemType}.subscribe-online`),
                  modality: "online",
                };
              }
              if (this.community.modality === "on-site" || this.isHybrid) {
                // Espacio on-site o hibrido. Ahora generamos el botón del onsite
                this.buttons.Onsite = {
                  text: this.$t(`enter-button.${this.itemType}.subscribe-on-site`),
                  modality: "on-site",
                };
              }
            }else{
                // No nos trae la modalidad. Ponemos el botón online pero en genérico
                this.buttons.Online = {
                  text: this.$t(`enter-button.${this.itemType}.subscribe`),
                  modality: "online",
                };
            }
          }
          // Le añadimos al botón la acción request
          for (const row of Object.keys(this.buttons)) {
            if (this.buttons[row].action !== "closed") {
              if(this.community.accessibility === 1){
                // REQUEST JOIN
                this.buttons[row].action = () =>
                  this.request(this.buttons[row].modality);
              }else if(this.community.accessibility === 0){
                // JOIN DIRECTLY
                this.buttons[row].action = () =>
                  this.join(this.buttons[row].modality);
              }
            }
          }
        }

        // Después revisamos la condición de si tiene aforo
        if (this.community.occupation) {
          // En caso de haber ocupación revisamos la ocupación actual. Si está llena cambiamos los textos de los botones existentes
          this.buttons = checkOccupation(this.community, this.buttons, {
            textClosedOnline: this.$t(
              "events.subscription.response.event-hasnt-places-online"
            ),
            textClosedOnsite: this.$t(
              "events.subscription.response.event-hasnt-places-onsite"
            ),
            generalClosed: this.$t(
              "events.subscription.response.event-hasnt-places"
            ),
          });
        }
      }
    },
    async redirectToOnboardingForm() {
      //this.$router.replace({
      this.$router.push({
        name: "onboarding-form",
        params: { communityId: this.community.slug },
      });
      return 0;
    },
    async unsubscribe() {
      try {
        await this.$store.$service[Service.BackendClient].post(
          "unsubscribeCommunityType",
          {
            communitySlug: this.community.slug,
            memberKey: this.currentMember.key,
          }
        );
        // redirección al detalle de la comunidad
        const communityDetail =
          process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            "{subdomain}",
            this.community.parentCollective.subdomain || "app"
          ).replace("{slug}", this.community.parentCollective.slug);
        window.location.href = `${communityDetail}/${this.itemType}/${this.community.slug}`;
        this.notifySuccess(
          this.$t("events.subscription.response.unsubscription-success")
        );
      } catch {
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    async join(modality) {
      try {
        const response = await this.$store.$service[Service.BackendClient].post(
          "joinsubcommunity",
          {
            communitySlug: this.community.slug,
            ...(modality === "on-site"
              ? { modality: true }
              : { modality: false }),
          }
        );
        if (response.data?.userJoined) {
          this.userJoined = response.data.userJoined;
        }

        this.redirect();

        this.notifySuccess(
          this.$t("events.subscription.response.subscription-success")
        );
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    async request(modality) {
      // Funcion de apuntar-se
      try {
        await this.$store.$service[Service.BackendClient].post(
          "requestjoinsubcommunity",
          {
            communitySlug: this.community.slug,
            staffsCanJoin: true,
            staffJoin: true,
            ...(modality === "on-site"
              ? { modality: true }
              : { modality: false }),
          }
        );
        this.redirect();
        setTimeout(() => {
          this.notifySuccess(this.$t("welcome.request"));
        }, 2000);
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    async declineInvitation() {
      try {
        await this.$store.$service[Service.BackendClient].put(
          "declineInvitation",
          {
            communitySlug: this.community.slug,
          }
        );
        // redirección al detalle de la comunidad
        const communityDetail =
          process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            "{subdomain}",
            this.community.parentCollective.subdomain || "app"
          ).replace("{slug}", this.community.parentCollective.slug);
        window.location.href = `${communityDetail}/${this.itemType}/${this.community.slug}`;
      } catch (error) {
        console.log(error);
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    checkDirectEnter() {
      return checkCommunityDatesNew(this.community) && !this.community.joinURL;
    },
    redirect() {
      const app = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        this.community.subdomain || "app"
      ).replace("{slug}", this.community.slug);
      if (this.community.hasForm) {
        // redirección al form
        window.location.href = `${app}/onboarding-form`;
      } else if (this.community.hasPrice) {
        // redirección al price
        window.location.href = `${app}/onboarding-plans`;
      } else if (this.userJoined && this.checkDirectEnter) {
        // redirecció a dins l'espai
        window.location.href = app;
      } else {
        window.location.href = `${app}/welcome`;
      }
    },
    isOver(endDate, startDate) {
      const isEndDate =
        this.$moment(endDate) || this.$moment(startDate).endOf("day");
      return this.$moment(Date.now()).isAfter(isEndDate);
    },
  },
};
</script>

<style></style>
