<template>
  <b-container v-if="loading" fluid>
    <b-row>
      <b-col
        v-for="(item, indx) in skeletonArray"
        :key="indx"
        cols="12"
        md="6"
        lg="3"
        class="mb-2"
      >
        <b-skeleton-img />
      </b-col>
    </b-row>
  </b-container>
  <div v-else-if="items.length > 0">
    <b-container fluid>
      <b-row v-if="typeItem">
        <draggable
          v-if="isDraggable"
          v-model="items"
          :list="items"
          :move="checkMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group class="row">
            <b-col
              v-for="(item, indx) in items"
              :key="indx"
              v-show="
                item.type.name.en === searchType.name ||
                item.type.name[currentLocale] === searchType.name ||
                searchType.name === $t('display-type-options.show-all')
              "
              cols="12"
              md="6"
              lg="4"
              xl="3"
              class="mb-2"
            >
              <div>
                <slot :item="item" />
              </div>
            </b-col>
          </transition-group>
        </draggable>
        <b-col
          v-else
          v-for="(item, indx) in items"
          :key="indx"
          v-show="
            item.type.name.en === searchType.name ||
            item.type.name[currentLocale] === searchType.name ||
            searchType.name === $t('display-type-options.show-all')
          "
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <div>
            <slot :item="item" />
          </div>
        </b-col>
        <!-- <b-col
          v-else
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <div v-for="(item, indx) in items" :key="indx">
            <pre>{{ item }}</pre>
            <div v-show="
              item.type.name.en === searchType.name ||
              item.type.name[currentLocale] === searchType.name ||
              searchType.name === $t('display-type-options.show-all')
            ">
              <slot :item="item" />
            </div>
          </div>
        </b-col> -->
      </b-row>
      <b-row v-if="!typeItem">
        <b-col
          v-for="(item, indx) in items"
          :key="indx"
          cols="12"
          md="6"
          lg="4"
          xl="3"
          class="mb-2"
        >
          <div>
            <slot :item="item" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-button
      v-if="hasMore"
      variant="link"
      class="d-block m-auto"
      :disabled="loadingNext"
      @click="handleLoadNextPage"
    >
      <b-spinner v-if="loadingNext" small />
      <template v-else>
        {{ $t("action.show-more") }}
      </template>
    </b-button>
  </div>
  <!-- Placeholder -->
  <b-row v-else-if="placeholder" class="horizontal-placeholder">
    <b-col cols="12">
      <img :src="placeholder" />
    </b-col>
    <b-col cols="12">
      <p class="color-primary">
        {{ placeholderMessage }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BSkeletonImg,
  BSpinner,
} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  name: "BaseGridContainer",
  components: {
    draggable,
    BContainer,
    BRow,
    BCol,
    BButton,
    BSkeletonImg,
    BSpinner,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: [Boolean, String],
      default: null,
    },
    placeholderMessage: {
      type: [Boolean, String],
      default: null,
    },
    searchType: {
      type: Object,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    hasMore: Boolean,
    loading: Boolean,
    loadingNext: Boolean,
    typeItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actualItems: [],
      isSending: false,
      dragging: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    skeletonArray() {
      return new Array(12).fill(null);
    },
  },
  methods: {
    checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.items];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
      // await this.handleEditOrder()
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [indx, item] of this.actualItems.entries()) {
        item.order = indx + 1;
      }

      await this.$store.dispatch("editItem", {
        noSet: true,
        item: {
          itemType: `/people/order`,
          requestConfig: {
            people: this.actualItems,
          },
        },
      });
      this.$emit("changeOrder", this.actualItems);
      this.isSending = false;
    },
    handleLoadNextPage() {
      this.$emit("load-next");
    },
  },
};
</script>
