<template>
  <app-scroll :options="options">
    <slot name="top" />

    <b-spinner v-if="loading" type="grow" small class="mx-auto d-block" />

    <div v-else-if="items && items.length > 0" class="y-scroll-container">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="x-scroll-element mx-0"
      >
        <slot name="item" :item="item" :index="index" />
      </div>
    </div>

    <!-- Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="placeholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ placeholderMessage }}
        </p>
      </b-col>
      <slot name="action-button" />
    </b-row>

    <!-- <b-spinner
      v-if="loadingNextPage"
      type="grow"
      small
      class="mx-auto d-block"
    /> -->

    <b-button
      v-if="items && items.length < total"
      class="d-block text-center m-auto"
      variant="link"
      :disabled="loadingNextPage"
      @click="loadNextPage"
    >
      {{ $t("action.load-more") }}
    </b-button>

    <slot name="footer" />
  </app-scroll>
</template>

<script>
import { BButton, BSpinner, BRow, BCol } from "bootstrap-vue";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";

export default {
  name: "BaseVerticalContainer",
  components: {
    AppScroll,
    BSpinner,
    BRow,
    BCol,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
    placeholderMessage: {
      type: String,
      default: "",
    },
    perPage: {
      type: Number,
      default: 16,
    },
    loading: Boolean,
    loadingNextPage: Boolean,
  },
  data() {
    return {
      currentPage: 0,
      options: {
        scrollPanel: { scrollingX: false },
      },
    };
  },
  methods: {
    loadNextPage() {
      this.currentPage += 1;
      this.$emit("load-next", {
        page: this.currentPage,
        perPage: this.perPage,
      });
    },
  },
};
</script>
