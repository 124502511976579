var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',_vm._l((_vm.skeletonArray),function(item,indx){return _c('b-col',{key:indx,staticClass:"mb-2",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-skeleton-img')],1)}),1)],1):(_vm.items.length > 0)?_c('div',[_c('b-container',{attrs:{"fluid":""}},[(_vm.typeItem)?_c('b-row',[(_vm.isDraggable)?_c('draggable',{attrs:{"list":_vm.items,"move":_vm.checkMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_c('transition-group',{staticClass:"row"},_vm._l((_vm.items),function(item,indx){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
              item.type.name.en === _vm.searchType.name ||
              item.type.name[_vm.currentLocale] === _vm.searchType.name ||
              _vm.searchType.name === _vm.$t('display-type-options.show-all')
            ),expression:"\n              item.type.name.en === searchType.name ||\n              item.type.name[currentLocale] === searchType.name ||\n              searchType.name === $t('display-type-options.show-all')\n            "}],key:indx,staticClass:"mb-2",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('div',[_vm._t("default",null,{"item":item})],2)])}),1)],1):_vm._l((_vm.items),function(item,indx){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
          item.type.name.en === _vm.searchType.name ||
          item.type.name[_vm.currentLocale] === _vm.searchType.name ||
          _vm.searchType.name === _vm.$t('display-type-options.show-all')
        ),expression:"\n          item.type.name.en === searchType.name ||\n          item.type.name[currentLocale] === searchType.name ||\n          searchType.name === $t('display-type-options.show-all')\n        "}],key:indx,staticClass:"mb-2",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('div',[_vm._t("default",null,{"item":item})],2)])})],2):_vm._e(),(!_vm.typeItem)?_c('b-row',_vm._l((_vm.items),function(item,indx){return _c('b-col',{key:indx,staticClass:"mb-2",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('div',[_vm._t("default",null,{"item":item})],2)])}),1):_vm._e()],1),(_vm.hasMore)?_c('b-button',{staticClass:"d-block m-auto",attrs:{"variant":"link","disabled":_vm.loadingNext},on:{"click":_vm.handleLoadNextPage}},[(_vm.loadingNext)?_c('b-spinner',{attrs:{"small":""}}):[_vm._v(" "+_vm._s(_vm.$t("action.show-more"))+" ")]],2):_vm._e()],1):(_vm.placeholder)?_c('b-row',{staticClass:"horizontal-placeholder"},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.placeholder}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"color-primary"},[_vm._v(" "+_vm._s(_vm.placeholderMessage)+" ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }